// 邮箱格式验证函数
export function isValidateEmail (email) {
    const mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    if (email.trim()) {
        if (!mailReg.test(email)) {
            return {
                showClose: true,
                duration: 1500,
                message: "邮箱格式不正确！"
            };
        }
    }
    return null; // 返回 null 表示验证通过
};
