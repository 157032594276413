<template>
	<div>
		<section class="header-bg" style="min-height: 255px;">
		</section>
		<!-- <section class="container-1200" style="margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">Suggest a journal</strong>
				</p>
			</div>
		</section> -->
		<!-- 暂时期刊列表 -->
<!-- 		<section class="container-1200" style="margin-top: 40px;" >

			<div style="height: 20px;">
				<div style="color: #31708f;background-color: #d9edf7;border-color: #bce8f1;padding: 10px 15px;border-bottom: 1px solid transparent;border-top-right-radius: 3px;border-top-left-radius: 3px;">
				<h3 class="panel-title"><strong>Journals With
						Plugin Running</strong></h3></div>
			</div>
			<div style="margin-top: 40px;">
				<el-tabs v-model="activeName" @tab-click="handleClick">


					<el-tab-pane :label="'All Journals' + '(' + journalCount + ')'" name="first" lazy>
						<div v-if="activeName === 'first'">
							<div style="margin: 15px 0; width: 40%;">
								<el-input placeholder="Please enter the name of the journal" v-model="journalName"
									class="input-with-select" @keyup.native.enter="handleClick">
									<el-button slot="append" icon="el-icon-search"
										@click="handleClick">Search</el-button>
								</el-input>
							</div>
							<div v-for="(journal,index) in journalTopList" :key="journal.id">
								<p class="journalT"><a :href="journal.journalWebsite">{{journal.journalName}} (<span
											style="margin-right: 10px;">ISSN:</span>{{journal.issn}})</a></p>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="'Scopus Journals' + '(' + scopusJournalCount + ')'" name="second" lazy>
						<div v-if="activeName === 'second'">
							<div style="margin: 15px 0; width: 40%;">
								<el-input placeholder="Please enter the name of the journal" v-model="journalName"
									class="input-with-select" @keyup.native.enter="handleClick">
									<el-button slot="append" icon="el-icon-search"
										@click="handleClick">Search</el-button>
								</el-input>
							</div>
							<div v-for="(journal,index) in journalTopList" :key="journal.id">
								<p class="journalT"><a :href="journal.journalWebsite">{{journal.journalName}} (<span
											style="margin-right: 10px;">ISSN:</span>{{journal.issn}})</a></p>
							</div>
						</div>
					</el-tab-pane>
										<el-tab-pane :label="'WoS Journals' + '(' + wosJournalCount + ')'" name="third">
						<div v-if="activeName === 'third'">
							<div style="margin: 15px 0; width: 40%;">
								<el-input placeholder="Please enter the name of the journal" v-model="journalName"
									class="input-with-select" @keyup.native.enter="handleClick">
									<el-button slot="append" icon="el-icon-search"
										@click="handleClick">Search</el-button>
								</el-input>
							</div>
							<div v-for="(journal,index) in journalTopList" :key="journal.id">
								<p class="journalT"><a :href="journal.journalWebsite">{{journal.journalName}} (<span
											style="margin-right: 10px;">ISSN:</span>{{journal.issn}})</a></p>
							</div>
						</div>
					</el-tab-pane>

				</el-tabs>
				<div style="margin-top: 40px;">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :prev-text="'Previous'" layout="prev, pager, next,jumper"
						:next-text="'Next'" :page-size="pageSize" :total="total">
					</el-pagination>


				</div>
			</div>
		</section> -->
		<section class="container-1200" style="margin-top: 40px;">
			<div class="panel-info">
				<div class="panel-heading">
					<h3 class="panel-title"><strong>Join Contrimetric Family</strong></h3>
				</div>
				<div class="panel-body">
					<div style="height: 20px;">
						<p class="title-hr" style="font-size: 25px;"><span style="font-size: 17.5px;">Apply Plugin
								code,<span style="color: red;"> Free of Charge</span> </span>
						</p>
					</div>

					<el-form :model="journalForm" label-width="80px" ref="journalForm" :rules="journalFormRules"
						class="form-group">
						<el-form-item label="url" prop="url">
							<el-input v-model="journalForm.url" placeholder="http://"
								@keyup.native.enter="onSubmit"></el-input>
						</el-form-item>
						<el-form-item label="Journal Title(In English)" prop="journalTitle">
							<el-input v-model="journalForm.journalTitle" placeholder="Journal Title"
								@keyup.native.enter="onSubmit"></el-input>
							<div style="line-height: 12px;color: #737373;font-size: 10px;">If your journal has
								non-english Title, Translate the title and enter here</div>
						</el-form-item>

						<el-form-item label="Journal Title in Original language (optional)">
							<el-input v-model="journalForm.originalLanguage" @keyup.native.enter="onSubmit"
								placeholder="Title in Original language"></el-input>
						</el-form-item>
						<el-form-item label="Journal Logo">
							<el-upload class="upload-demo" :auto-upload="true" :multiple="false" :limit="1"
								:on-exceed="handleExceed" :http-request="uploadHttpRequest" action="#" ref="upload">
								<el-button size="small" type="primary">Select the file</el-button>
								<div slot="tip" class="el-upload__tip"
									style="line-height: 32px;font-size: 12px;margin: 7px 0px 0px 5px"
									v-if="fileSelected">No file selected</div>
							</el-upload>
							<div style="line-height: 12px;color: #737373;font-size: 10px;">Unqiue Logo Name to avoid
								name collision - Keep the logo file name as your journal short name OR journal ISSN etc.
							</div>
						</el-form-item>
						<el-form-item label="Doi Prefix" prop="doi">
							<el-input v-model="journalForm.doi" placeholder="like 10.85470/"
								@keyup.native.enter="onSubmit"></el-input>
						</el-form-item>
						<el-form-item label="Subject">
							<!-- <el-select v-model="journalForm.subjectType" placeholder="请选择">
								<el-option v-for="item in optionsSubject" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select> -->
							<el-input v-model="journalForm.subjectType" placeholder="Subject"
								@keyup.native.enter="onSubmit"></el-input>
						</el-form-item>

						<el-form-item label="ABSTRACTING/INDEXING" class="wrap">
							<el-tree ref="tree" :data='abstactData' show-checkbox node-key="id" :props="defaultProps"
								:render-content="renderContent" default-expand-all>

							</el-tree>
						</el-form-item>

						<el-form-item label="Journal ISSN" prop="journaISSN">
							<el-input v-model="journalForm.journaISSN" placeholder="Journal ISSN"
								@keyup.native.enter="onSubmit"></el-input>
							<div style="line-height: 12px;color: #737373;font-size: 10px;">ISSN no. only, no other Text.
								example: 0000-0000 / Keep it blank if your journal has no ISSN assigned.</div>
						</el-form-item>
						<el-form-item label="Journal ESSN">
							<el-input v-model="journalForm.journaESSN" placeholder="Journal ESSN"
								@keyup.native.enter="onSubmit"></el-input>
							<div style="line-height: 12px;color: #737373;font-size: 10px;">EISSN no. only, no other
								Text. example: 0000-0000 / Keep it blank if your journal has no EISSN assigned.</div>
						</el-form-item>
						<el-form-item label="Publisher">
							<el-input v-model="journalForm.publisher" placeholder="Publisher"
								@keyup.native.enter="onSubmit"></el-input>
						</el-form-item>
						<el-form-item label="Start year since the online OA fulltext content is available">
							<el-input v-model="journalForm.startYear" placeholder="Start year"
								@keyup.native.enter="onSubmit"></el-input>
						</el-form-item>
						<el-form-item label="Country">
							<el-input v-model="journalForm.country" placeholder="Country"
								@keyup.native.enter="onSubmit"></el-input>
						</el-form-item>
						<el-form-item label="Creative Commons- CC License (if any)">
							<el-select v-model="journalForm.licenseType" placeholder="请选择">
								<el-option v-for="item in creativeCommons" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="Author pays to publish">
							<el-radio-group v-model="journalForm.radioType">
								<el-radio :label="option.value" v-for="option in radioOptions"
									:key="option.value">{{ option.label }}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="Journal-contact name">
							<el-input v-model="journalForm.contactName" placeholder="Journal-contact name"
								@keyup.native.enter="onSubmit"></el-input>
							<div style="line-height: 12px;color: #737373;font-size: 10px;">Name of the Contact Person OR
								Designation. No Other Details</div>
						</el-form-item>
						<el-form-item label="Journal-contact email" prop="contactEmail">
							<el-input v-model="journalForm.contactEmail" placeholder="Journal-contact email"
								@keyup.native.enter="onSubmit"></el-input>
							<div style="line-height: 12px;color: #737373;font-size: 10px;">One Email Id only. Mail
								communication will fail in case more than one email id.</div>
						</el-form-item>
						<el-form-item label="Journal-contact phone">
							<el-input v-model="journalForm.contactphone" placeholder="Journal-contact phone"
								@keyup.native.enter="onSubmit"></el-input>

						</el-form-item>
						<el-form-item label="Description">
							<el-input v-model="journalForm.description" type="textarea" :rows="3"
								placeholder="Description" @keyup.native.enter="onSubmit"></el-input>
						</el-form-item>
						<el-form-item label="Keywords">
							<el-input v-model="journalForm.keywords" placeholder="Keywords"
								@keyup.native.enter="onSubmit"></el-input>
						</el-form-item>
						<el-form-item label="Language(s) use , (comma) as separator">
							<el-input v-model="journalForm.language" placeholder="Language  "
								@keyup.native.enter="onSubmit"></el-input>
							<div style="line-height: 12px;color: #737373;font-size: 10px;">Publication language of
								journal</div>

						</el-form-item>
						<el-form-item label="Your name">
							<el-input v-model="journalForm.name" placeholder="Your name"
								@keyup.native.enter="onSubmit"></el-input>
							<div style="line-height: 12px;color: #737373;font-size: 10px;">Name of second preferred
								contact of journal OR your name.</div>
						</el-form-item>
						<el-form-item label="Your Email" prop="email">
							<el-input v-model="journalForm.email" placeholder="Your Email"
								@keyup.native.enter="onSubmit"></el-input>
							<div style="line-height: 12px;color: #737373;font-size: 10px;">One Email Id only. Mail
								communication will fail in case more than one email id. And System will delete journal
								from database.
							</div>
						</el-form-item>


					</el-form>
					<button class="btn btn-primary" @click="onSubmit">Submit</button>
				</div>
			</div>
		</section>

		
	</div>
</template>

<script>
	import $http from "@/request/http";
	import {
		isValidateEmail
	} from "@/utils/validate"
	export default {

		name: "suggest",
		data() {

			//校验邮箱
			const validateEmaill = (rule, value, callback) => {
				const validationResult = isValidateEmail(value); // 使用 validateContactEmail 函数验证邮箱
				if (validationResult) {
					callback(new Error(validationResult.message)); // 如果验证结果不为空，说明验证失败，返回错误信息
				} else {
					callback(); // 验证通过，不返回错误信息
				}
			};
			return {
				journalName: '', //搜索框的值


				abstactData: [{
						id: 1,
						level: 1,
						disabled: true,
						label: "Web of Science(Clarivate)",
						children: [{
								id: 11,
								level: 2,
								label: "SCI",
								inputValue: ''
							},
							{
								id: 12,
								level: 2,
								label: "SSCI",
								inputValue: ''
							},
							{
								id: 13,
								level: 2,
								label: "A&HCI",
								inputValue: ''
							},
							{
								id: 14,
								level: 2,
								label: "ESCI",
								inputValue: ''
							}

						]
					},
					{
						id: 2,
						level: 1,
						label: "Scopus(Elsevier)",
					},
					{
						id: 3,
						level: 1,
						label: "Others",
						disabled: true,
						children: [{
								id: 31,
								level: 2,
								label: "Compendex(Elsevier)",
								inputValue: ''
							},
							{
								id: 32,
								level: 2,
								label: "INSPEC(IET)",
								inputValue: ''
							},
							{
								id: 33,
								level: 2,
								label: "PubMed",
								inputValue: ''
							},
							{
								id: 34,
								level: 2,
								label: "CA",
								inputValue: ''
							},
							{
								id: 35,
								level: 2,
								label: "Ebsco",
								inputValue: ''
							},
							{
								id: 36,
								level: 2,
								label: "DOAJ",
								inputValue: ''
							}
						]
					}


				],
				defaultProps: {
					children: 'children',
					label: 'label',
					inputValue: 'inputValue'
				},
				tabList: [{
						label: 'Journals',
						value: '1',
						name: 'first',
					},
					{
						label: 'Scopus Journals',
						value: '2',
						name: 'second',
					},
					{
						label: 'WoS Journals',
						value: '3',
						name: 'third',
					},

				],
				journalTopList: [{
					journalName: '12',
				}],
				radioOptions: [{
						label: 'Yes',
						value: '1'
					},
					{
						label: 'No',
						value: '2'
					},
					{
						label: 'Conditional',
						value: '3'
					}
				],
				journalCount: '',
				scopusJournalCount: '',
				wosJournalCount: '',
				activeName: 'first',
				journalType: '1',
				journalForm: {
					url: '',
					journalTitle: '',
					originalLanguage: '',
					attachmentUrl: '',
					doi: '',
					subjectType: '',
					journaISSN: '',
					journaESSN: '',
					publisher: '',
					startYear: '',
					country: '',
					licenseType: '',
					radioType: '1',
					contactName: '',
					contactEmail: '',
					contactphone: '',
					description: '',
					keywords: '',
					language: '',
					userName: '',
					email: '',
					journalFirstType: [],
					journalSecondType: [],
					jifList: [],
					totalIds: []
				},
				journalFormRules: {
					url: [{
						required: true,
						trigger: "change",
						message: "Please enter a URL"
					}],
					journalTitle: [{
						required: true,
						trigger: "change",
						message: "Please enter journalTitle"
					}],
					doi: [{
						required: true,
						trigger: "change",
						message: "Please enter doi"
					}],
					journaISSN: [{
						required: true,
						trigger: "change",
						message: "Please enter issn"
					}],
					email: [{
						required: false,
						trigger: "change",
						validator: validateEmaill
					}],
					contactEmail: [{
						required: false,
						trigger: "change",
						validator: validateEmaill
					}],
				}, //格式校验
				fileSelected: true, //是否隐藏上传附件的tip
				optionsSubject: [{
					value: 'Agriculture (General)',
					label: 'Agriculture (General)'
				}, {
					value: 'Acoustics',
					label: 'Acoustics'
				}, {
					value: 'Architecture',
					label: 'Architecture'
				}, {
					value: 'Arts in general',
					label: 'Arts in general'
				}, {
					value: 'Biochemistry',
					label: 'Biochemistry'
				}, {
					value: 'Biology',
					label: 'Biology'
				}, {
					value: 'Biotechnology',
					label: 'Biotechnology'
				}, {
					value: 'Botany',
					label: 'Botany'
				}, {
					value: 'Business and Management',
					label: 'Business and Management'
				}, {
					value: 'Computer Science',
					label: 'Computer Science'
				}, {
					value: 'Education',
					label: 'Education'
				}, {
					value: '11',
					label: 'Environmental Sciences'
				}, {
					value: 'Gastroenterology',
					label: 'Gastroenterology'
				}, {
					value: 'Geography',
					label: 'Geography'
				}, {
					value: 'Geology',
					label: 'Geology'
				}, {
					value: 'Geophysics and Geomagnetism',
					label: 'Geophysics and Geomagnetism'
				}, {
					value: 'History',
					label: 'History'
				}, {
					value: 'Languages and Literatures',
					label: 'Languages and Literatures'
				}, {
					value: 'Law',
					label: 'Law'
				}, {
					value: 'Library and Information Science',
					label: 'Library and Information Science'
				}, {
					value: 'Linguistics',
					label: 'Linguistics'
				}, {
					value: 'Mathematics',
					label: 'Mathematics'
				}, {
					value: 'Medicine (General)',
					label: 'Medicine (General)'
				}, {
					value: 'Sociology',
					label: 'Sociology'
				}], //subject

				creativeCommons: [{
					value: '1',
					label: 'Attribution Non-commercial No Derivatives (by-nc-nd)'
				}, {
					value: '2',
					label: 'Attribution Non-commercial Share Alike (by-nc-sa)'
				}, {
					value: '3',
					label: 'Attribution Non-commercial (by-nc)'
				}, {
					value: '4',
					label: 'Attribution No Derivatives (by-nd)'
				}, {
					value: '5',
					label: 'Attribution Share Alike (by-sa)'
				}, {
					value: '6',
					label: 'Attribution (by)'
				}, {
					value: '7',
					label: 'None'
				}], //creativeCommons
				currentPage: 1, //分页当前页
				total: 20,
				pageSize: 10,
				hidden:false,
			}
		},
		mounted() {
			// this.journalForm.subjectType = this.optionsSubject[0].value;
			this.journalForm.licenseType = this.creativeCommons[0].value;
			this.getjournalCount1()
			this.handleClick()

		},
		methods: {



			//文件超出时
			handleExceed(params) {
				this.$message.error('文件超出限制')
			},
			// 上传附件的方法开始
			// beforeUpload(file) {
			// 	const isLt10M = file.size / 1024 / 1024 < 20;
			// 	if (!isLt10M) {
			// 		this.$message.error('上传头像图片大小不能超过 10MB!');
			// 	}
			// 	return isLt10M;

			// },
			// 自定义上传方法，param是默认参数，可以取得file文件信息
			uploadHttpRequest(params) {
				if (params) {
					this.fileSelected = false
				}
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				// FormData对象
				const formData = new FormData()
				// 添加文件对象,key为需要发送的参数
				formData.append('file', params.file)
				$http.postI("user/uploadJournalLogo", formData, true).then(res => {
					this.journalForm.attachmentUrl = res.data
					loading.close()
					this.$message.success('上传附件成功')

				}).catch(err => {
					console.log(err);
				});

			},

			// 上传附件的方法结束

			//ABSTRACTING/INDEXING
			abstractI() {
				let checkedNodes = [];
				let treeKeys = [];
				let ids = [];

				checkedNodes = this.$refs.tree.getHalfCheckedKeys();
				treeKeys = this.$refs.tree.getCheckedKeys();
				//子节点+父节点 用set去重
				ids = Array.from(new Set(checkedNodes.concat(treeKeys)))
				this.journalForm.totalIds = ids;
				//进行分类
				for (var i = 0; i < ids.length; i++) {
					var nodes = this.$refs.tree.getNode(ids[i]);
					if (nodes.level == 1) {
						this.journalForm.journalFirstType.push(ids[i]);

					} else if (nodes.level == 2) {

						this.journalForm.journalSecondType.push(ids[i]);
					}
					//进行inputvalue注入
					var nodes = this.$refs.tree.getNode(ids[i])
					if (nodes.data.inputValue == null || nodes.data.inputValue === '') {
						this.journalForm.jifList.push('0')
					} else {
						this.journalForm.jifList.push(nodes.data.inputValue)
					}
				}
				// console.log(this.journalForm.journalSecondType)
			},

			renderContent(h, {
				node,
				data
			}) {
				let a = data.id.toString()[0]
				if(a=='1'){
					//一级
					if(node.level == 1){
						return h('span', data.label);
					}else{
						return h('span', [
							h('span', {
								style: 'margin-right: 8px',
						
							}, data.label),
							h('span', {
								style: 'margin-right: 8px',
						
							}, ' JIF:'),
							h('input', {
								style: 'border:none;border-bottom:1px solid #DCDFE6;width:35%',
								domProps: {
									value: data.inputValue
								},
								on: {
									input: (event) => {
										// 更新节点数据
										data.inputValue = event.target.value;
									}
								}
							})
						
						]);
					}
				}
				
				if(a=='2'){
					return h('span', [
						h('span', {
							style: 'margin-right: 8px',
					
						}, data.label),
						h('span', {
							style: 'margin-right: 8px',
					
						}, '  CiteScore:'),
						h('input', {
							style: 'border:none;border-bottom:1px solid #DCDFE6;width:35%',
							domProps: {
								value: data.inputValue
							},
							on: {
								input: (event) => {
									// 更新节点数据
									data.inputValue = event.target.value;
								}
							}
						})
					
					]);
					}
					if(a=='3'){
						return h('span', data.label);
					}
			},


			//表单提交
			onSubmit() {
				this.abstractI()
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				this.$refs.journalForm.validate(valid => {
					if (valid) { //验证
						$http.postI("user/insertJournalForm", this.journalForm, true).then(res => {
							loading.close();
							this.$message.success('上传成功');
							// 清空 journalForm 中的每个字段的值
							for (let key in this.journalForm) {
								if (this.journalForm.hasOwnProperty(key)) {
									this.journalForm[key] = '';
								}
								//数组赋值
								this.journalForm.journalFirstType = [];
								this.journalForm.journalSecondType = [];
								this.journalForm.jifList = [];
								//清空树
								this.$refs.tree.setCheckedKeys([]);
								//枚举类赋值
								this.journalForm.licenseType = this.creativeCommons[0].value;
								this.journalForm.radioType = 1;
							}
						}).catch(err => {
							loading.close()
						})
					} else {
						loading.close()
						this.$message.warning("请输入完整的内容")
					}
				})


			},



			getjournalCount1() {
				$http.postJ("getJournalCount", true).then(res => {
					this.journalCount = res.data.journalCount
					this.scopusJournalCount = res.data.scopusJournalCount
					this.wosJournalCount = res.data.wosJournalCount


				}).catch(err => {
					console.log(err);

				});
			},
			//选项卡处理，获取数据
			handleClick(tab, event) {

				if (this.activeName == 'first') {
					this.journalType = this.tabList[0].value
				} else if (this.activeName == 'second') {
					this.journalType = this.tabList[1].value
				} else {
					this.journalType = this.tabList[2].value
				}
				//添加加载会使页面晃动
				// const loading = this.$loading({
				// 	lock: true,
				// 	text: 'Loading',
				// 	spinner: 'el-icon-loading',
				// 	background: 'rgba(0, 0, 0, 0.7)'
				// });
				let paramp = {
					journalType: this.journalType,
					page: this.currentPage,
					pageSize: this.pageSize,
					journalName: this.journalName
				}
				$http.postJ("getJournalPage", paramp, true).then(res => {
					this.journalTopList = res.data.records
					this.total = res.data.total
					this.pageSize = res.data.size
					// loading.close()

				}).catch(err => {
					console.log(err);
					// loading.close()
				});

			},
			//处理分页
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.handleClick()

			},




		}
	}
</script>

<style lang="scss" scoped>
	.panel-info {
		margin-bottom: 20px;
		background-color: #fff;
		border: 1px solid transparent;
		border-radius: 4px;
		-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
		box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
		border-color: #bce8f1;
	}

	.panel-info>.panel-heading {
		color: #31708f;
		background-color: #d9edf7;
		border-color: #bce8f1;
	}

	.panel-heading {
		padding: 10px 15px;
		border-bottom: 1px solid transparent;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}

	.panel-body {
		padding: 15px;
	}

	.form-group {
		margin: 30px 0px;
	}

	::v-deep .form-group .el-form-item {
		display: flex;
		flex-direction: column;
		margin-bottom: 12px;
	}

	::v-deep .el-input__inner {
		height: 34px;
	}

	::v-deep .el-form-item__label {
		margin-left: 38px !important;
		font-weight: 600;
		line-height: 26px;
		text-align: unset !important;
		width: unset !important;
	}

	::v-deep .el-form-item__content {
		margin-left: 38px !important;
	}

	::v-deep .upload-demo {
		display: flex;
		border-radius: 4px;
		border: 1px solid #DCDFE6;
	}

	::v-deep .el-button--small {
		padding: 7px 15px;
		margin-left: 10px;
		background-color: #efefef;
		color: #000000;
		border: 1px solid black;
	}

	::v-deep .el-select {
		width: 100%;
	}

	.btn {
		margin-left: 38px;
		display: inline-block;
		margin-bottom: 0;
		font-weight: 400;
		background-color: #3276b1;
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
		background-image: none;
		border: 1px solid transparent;
		white-space: nowrap;
		padding: 6px 12px;
		font-size: 14px;
		line-height: 1.42857143;
		border-radius: 4px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		color: #fff;
	}

	.btn-primary:hover {
		color: #fff;
		background-color: #3276b1;
		border-color: #285e8e;

	}

	::v-deep .el-tabs__item {
		font-size: 20px;
	}

	::v-deep .el-tabs__item.is-active {
		color: #1da6b8;
		font-weight: 600;
	}

	::v-deep .el-tabs__active-bar {
		background-color: #1da6b8;
	}

	::v-deep .el-tabs__item:hover {
		color: #1da6b8;
	}

	.journalT {
		line-height: 26px;
		font-size: 16px;
	}

	::v-deep .el-pager li.active {
		color: #1da6b8;
		cursor: pointer;
	}

	::v-deep .el-pager li:hover {
		color: #1da6b8;
	}

	::v-deep .el-pagination button:hover {
		color: #1da6b8;
	}

	::v-deep .el-input__inner:focus {
		border-color: #1da6b8;
		outline: 0;
	}

	//单选按钮
	::v-deep .el-radio__inner:hover {
		color: #1da6b8;
		border-color: #1da6b8;
	}

	::v-deep .el-radio__input.is-checked .el-radio__inner {
		background-color: #1da6b8 !important;
		border-color: #1da6b8 !important;
	}

	::v-deep .el-radio__input.is-checked+.el-radio__label {
		color: #1da6b8 !important;
	}

	// 禁用框不显示
	::v-deep .el-checkbox__input.is-disabled {
		display: none;
	}
</style>